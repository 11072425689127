<template>
  <v-container fill-height>
    <v-row dense>
      <v-col cols="12" class="d-flex">
        <v-spacer />
        <v-form v-model="valid" ref="form">
          <v-card width="800">
            <v-card-title>
              <span class="headline">{{ cardLabel }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      aut-reset-password-id
                      name="user_id"
                      v-model="user_id"
                      label="ID*"
                      hint="Please enter your User ID"
                      persistent-hint
                      :rules="idRules"
                      validate-on-blur
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn aut-action-reset-password-cancel @click="cancelReset">
                Cancel
              </v-btn>
              <v-btn
                aut-action-reset-password-submit
                color="primary"
                :disabled="!valid"
                :loading="showLoader"
                @click="submitReset"
              >
                {{ cardLabel }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-spacer />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { navigateTo, rules } from "@/util.js";
const debug = require("debug")("atman.components.reset_password");
debug("reset_password");
export default {
  name: "ResetPassword",
  data: () => ({
    showLoader: false,
    valid: false,
    cardLabel: "Reset Password",
    user_id: "",
    idRules: [rules.mandatory()],
  }),
  methods: {
    ...mapActions("user", ["resetPassword"]),
    cancelReset() {
      this.$refs.form.reset();
      navigateTo(this.$router, "/login");
    },
    async submitReset() {
      const component = this;
      try {
        component.showLoader = true;
        await component.resetPassword({
          id: component.user_id,
        });
        component.$refs.form.reset();
        component.displaySuccessMessage(
          "Reset Successful. Please check your email for further instructions"
        );
      } catch (e) {
        component.displayErrorMessage("Reset Unsuccessful");
        debug("reset failed");
      } finally {
        component.showLoader = false;
      }
    },
  },
};
</script>
